<h1 mat-dialog-title class="primary-color">
  {{ "General.ConfirmDelete" | translate }}
</h1>
<div mat-dialog-content>
  {{ "labels.ConfirmDeleteMsg" | translate }}
  <span class="danger font-weight-bold"> {{ data.msg | translate }} </span>
</div>
<div mat-dialog-actions align="center">
  <button mat-raised-button [mat-dialog-close]="false" class="btn btn-info">
    {{ "General.NoThanks" | translate }}
  </button>
  <button
    mat-raised-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    class="btn btn-danger"
  >
    {{ "General.Delete" | translate }}
  </button>
</div>
