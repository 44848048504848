  <mat-form-field appearance="legacy">
    <mat-label>{{ "labels.Teacher" | translate }}</mat-label>
    <mat-select [formControl]="control">
      <mat-option>{{
        "General.Choose" | translate
      }}</mat-option>
      <mat-option
        *ngFor="let teacher of teachers"
        [value]="teacher.teacherUserId"
      >
        {{ teacher.teacher_ar_name }}
      </mat-option>
    </mat-select>
    <mat-hint>
      <input-validation-msg
        [control]="control"
        [submitted]="submitted"
        [isSelect]="true"
      >
      </input-validation-msg>
    </mat-hint>
  </mat-form-field>
