import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { teacherByEduCompId } from "app/shared/models/general/general";
import { GeneralService } from "app/shared/services/General/general.service";
import { AuthService } from "app/shared/services/auth/auth.service";

@Component({
  selector: "app-teacher-by-educational-component-Id",
  templateUrl: "./teacher-by-educational-component-Id.component.html",
  styleUrls: ["./teacher-by-educational-component-Id.component.scss"],
})
export class TeacherByEducationalComponentIdComponent implements OnInit {
  teachers: teacherByEduCompId[];
  EduCompId: number;

  @Input() control: FormControl;
  @Input() subjectControl: FormControl;
  @Input() submitted: boolean;

  constructor(
    public authserv: AuthService,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.EduCompId = Number(this.authserv.getEduCompId());
    this.onSelectSubjects();
  }
  onSelectSubjects() {
    this.generalService
      .getTeacherBySubjectAndEduCompId(this.EduCompId, 0)
      .subscribe((response: any) => {
        if (response) {
          this.teachers = response.teachers;
          console.log("edu",this.EduCompId);

          console.log("hhhh",response.teachers)
        }
      });
  }
}
