<mat-form-field [appearance]="appearance" [class]="class">
  <mat-label>
    {{ label | translate }}
  </mat-label>
  <input
    matInput
    [type]="type"
    [formControl]="control"
    [autocomplete]="autocomplete"
    [placeholder]="placeholder | translate"
  />
  <mat-hint>
    <input-validation-msg [control]="control" [submitted]="submitted">
    </input-validation-msg>
  </mat-hint>
</mat-form-field>
