import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { FileModel } from "../models/general/general";

@Injectable({
  providedIn: "root",
})
export class UploadFileService {
  constructor() { }

  async uploadFile(event: any) {
    let file = event.target.files[0];
    if (file) {
      let data = {} as FileModel;
      var reader = new FileReader();
      reader?.readAsDataURL(file);
      reader.onload = (event: any) => {
        data.FileAsBase64 = event.target.result; //replace("data:application/pdf;base64,","");
      };
      data.name = file.name;
      data.size = file.size;
      data.type = file.type;
      data.LastModified = file.lastModified;
      data.LastModifiedDate = file.lastModifiedDate;
      return await data;
    } else return null;
  }


  downloadFile(url: string) {
    window.open(environment.apiURL.replace("/api/", "") + url, "_blank");
  }

  previewPicture(url: any) {
    if (url) {
      if (!url.FileAsBase64)
        return environment.apiURL.replace("/api/", "") + url;
      else return url.FileAsBase64;
    }
  }

  resetFileInputs() {
    const FileInputs = document.querySelectorAll('input[type="file"]');
    FileInputs.forEach((input: any) => {
      input.value = null;
    });
  }

  resetFileById(htmlId: string) {
    console.log("htmlId", htmlId);
    if (htmlId != null)
      (<HTMLInputElement>document?.getElementById(htmlId)).value = null;
  }
}
